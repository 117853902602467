<header id="simpli-header" class="sticky top-0 el-header app-header">
  <div class="el-container h-full">
    <div
      class="el-row w-full el-row--flex is-align-middle is-justify-space-between h-full"
    >
      <div class="el-col el-row--flex is-align-middle">
        <div class="simpli-logo">
          <button
            class="hdr-btn el-button els-button--tooltip el-button--text"
            type="button"
            [matMenuTriggerFor]="switchPlatform"
          >
            <img [src]="headerMenuIcon" alt="Simpli Menu" />
          </button>
          <mat-menu class="hdr-menu" #switchPlatform="matMenu">
            <button mat-menu-item>
              <a [href]="appUrl" target="_blank">
                <img class="platform-img" [src]="dspImg" alt="Simplifi DSP" />
              </a>
            </button>
            <button mat-menu-item>
              <img
                class="platform-img"
                [src]="plannerImg"
                alt="Simplifi Media Planner"
              />
            </button>
          </mat-menu>
          <div class="logo-img">
            <a [routerLink]="homeUrl">
              <img [src]="headerLogo" alt="Simpli.fi Media Planner" />
            </a>
          </div>
        </div>
      </div>
      <div class="el-col pl-1">
        <div class="el-row--flex is-align-middle is-justify-end">
          <button
            type="button"
            class="hdr-btn el-button els-button--tooltip el-button--text"
            matTooltip="Info"
            (click)="isOpen = !isOpen"
            type="button"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
          >
            <i class="el-icon-question"></i>
          </button>
          <div class="notifications relative">
            <button
              type="button"
              class="hdr-btn el-button els-button--tooltip el-button--text notification-btn"
              (click)="openNotifications()"
            >
              <i class="el-icon-bell"></i>
              <span *ngIf="unreadNotif" class="notification-dot"></span>
            </button>
            <div
              class="notifications-wrapper custom-menu"
              *ngIf="showNotificationMenu"
            >
              <simplifi-notification-menu
                class="flex-1"
                (action)="handleNotificationActions($event)"
              ></simplifi-notification-menu>
            </div>
          </div>

          <button
            type="button"
            class="hdr-btn el-button els-button--tooltip el-button--text"
            [matMenuTriggerFor]="userLogout"
          >
            <img [src]="userIcon" alt="User" />
          </button>
          <mat-menu
            class="hdr-menu notification-menu"
            #userLogout="matMenu"
            xPosition="before"
          >
            <button mat-menu-item (click)="navigateToProfile()">
              <div
                class="list-content el-row--flex is-align-middle is-justify-space-between"
              >
                <div class="el-row--flex is-align-middle gap-x-2">
                  <i class="el-icon-user"></i>

                  <div class="ls-text text-primary">Profile</div>
                </div>
              </div>
            </button>
            <button mat-menu-item (click)="logout()">
              <div
                class="list-content el-row--flex is-align-middle is-justify-space-between"
              >
                <div class="el-row--flex is-align-middle gap-x-2">
                  <i class="el-icon-logout"></i>
                  <div class="ls-text text-primary">Logout</div>
                </div>
              </div>
            </button>
          </mat-menu>
          <div class="hdr-team el-row--flex is-align-middle gap-x-2">
            <i class="el-icon-organization"></i>
            <div class="els-row--columm">
              <div class="el-breadcrumb">
                <span
                  class="el-breadcrumb__item"
                  *ngIf="user?.tenant?.parentName"
                >
                  <span
                    class="el-breadcrumb__inner overflow-hidden whitespace-nowrap text-ellipsis"
                    [matTooltip]="
                      getTooltipContent(user?.tenant?.parentName ?? '')
                    "
                  >
                    {{ user?.tenant?.parentName }}
                  </span>
                  <i class="el-breadcrumb__separator el-icon-arrow-right"> </i>
                </span>
              </div>
              <div class="hrd-name">
                <span
                  class="el-breadcrumb__inner overflow-hidden whitespace-nowrap text-ellipsis"
                  [matTooltip]="getTooltipContent(user?.tenant?.name ?? '')"
                  >{{ user?.tenant?.name }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<!-- This template displays the overlay content and is connected to the Questions button -->
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="custom-overlay-main"
  (backdropClick)="closeOverlay()"
>
  <div class="questions-card el-card is-always-shadow">
    <div class="el-card__body">
      <div class="card-top el-row--flex gap-x-2 is-align-middle">
        <div class="flex-1">
          Welcome to the Simpli.fi Help Center. What can we help you with?
        </div>
        <div class="flex-initial">
          <img class="help-img" [src]="callAvatar" alt="Avatar" />
        </div>
      </div>
      <div class="q-search clearance-opposite flex items-center">
        <a href="http://simpli.fi/" target="_blank" title="">
          Looking for more information?
        </a>
      </div>
      <div class="q-action-list clearance-opposite">
        <div class="list-content">
          <div class="lt-item">
            <div class="el-row--flex is-align-middle gap-x-2">
              <span class="icon-cont">
                <i class="el-icon-book"></i>
              </span>
              <div class="list-desc">
                <h3
                  class="el-message__content text-primary font-semibold lt-title"
                >
                  <a
                    href="https://simplifi.zendesk.com/hc/en-us"
                    target="_blank"
                    title="Help Center"
                    >Help Center</a
                  >
                </h3>
                <p class="lt-text">
                  FAQs, best practices, and important info for using the
                  Simpli.fi platform
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
