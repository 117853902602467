export class MediaType {
  id!: string;
  allocatedBudget!: number;
  allocatedBudgetPercent!: number;
  allocatedBudgetPercentOriginal!: number;
  defaultBudget!: number;
  defaultBudgetPercent!: number;
  recommendedMinBudget!: number;
  recommendedMaxBudget!: number;
  recommendedDefaultMinBudget!: number;
  recommendedDefaultMaxBudget!: number;
  maxBid!: number;
  defaultMaxBid!: number;
  name!: string;
  isChecked!: boolean;
}
