import {AiBotMention, AiBotName} from '@simplifi/core/constants';
import {Messages} from '../constants';

export enum ChannelType {
  PlanChat = 'plan-chat',
}
export const DEFAULT_MESSAGE_1 = `Welcome to SimpliFi's Planning Tool! You can build a plan on your own, or we can assist you with our friendly AI helper ${AiBotName}. Feel free to ask anytime you have a question, just tag @${AiBotMention} in your message.`;

export const DEFAULT_MESSAGE_2 = `To help you get started, we have some questions that will assist in creating your first plan. Just remember, you can ask for help anytime.`;

export const DEFAULT_MESSAGE_3 = `Welcome! Feel free to send me some questions to start a Plan together or you can send me the URL of the advertiser you are advertising to start.`;

export const DISCARD_MESSAGE =
  ' Do you want to continue with chat where you left off ?';

export enum ChannelMessageType {
  Text = 'text',
  Quote = 'quote',
  customMessage = 'link',
}

export enum SenderName {
  You = 'You',
  AiBot = AiBotName, //NOSONAR
}

export const singleOccupantBanner = {
  message: Messages.singleOccupantMessage,
  color: '#9ec7dd',
};

export const multiOccupantBanner = {
  message: Messages.multiOccupantMessage,
  color: '#9ec7dd',
};
export enum ChatButtonAction {
  Navigate = 'navigate',
  Delete = 'delete',
}

export const PLAN_LINK_MESSAGE =
  'Plan create successfully, click the below link to access the plan.';

export const DRAFT_CONFIRMATION_MESSAGE_ID = 'draft-confirmation';
