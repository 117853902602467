import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  HostListener,
} from '@angular/core';
import {Router} from '@angular/router';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AuthService, User} from '@simplifi/core/auth';
import {ComponentBaseDirective} from '@simplifi/core/component-base';
import {UserSessionStoreService} from '@simplifi/core/store';
import {environment} from '@simplifi/env/environment';
import {NotificationType, Numbers} from '@simplifi/shared/enums';
import {
  DownloadHelperService,
  PubnubService,
  UserProfileDataService,
} from '@simplifi/shared/services';
import Pubnub from 'pubnub';
import {
  INotification,
  INotificationAction,
} from '@simplifi/main/notifications/interfaces';
import {IToaster, TOASTER_SERVICE_KEY} from '@simplifi/core/toaster';
import {takeUntil} from 'rxjs';
import {PlanFacadeService} from '@simplifi/main/planner/services';
import {IAnyObject} from '@simplifi/core/i-any-object';
import {SnackBarComponent} from '../snack-bar/snack-bar.component';
import {MatSnackBar, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'simplifi-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent extends ComponentBaseDirective implements OnInit {
  headerMenuIcon = '/assets/images/header/switch-icon.svg';
  headerLogo = '';
  loaderId = 'header-notif-loader';
  showNotificationMenu = false;
  extraSmallSimplifiLogo = '/assets/ico/simplifi-small-logo.svg';
  SimplifiLogo = '/assets/images/header/header-logo.svg';
  userIcon = '/assets/images/header/user.svg';
  dspImg = '/assets/images/header/simplifi-dsp.png';
  plannerImg = '/assets/images/header/simplifi-planner.png';
  callAvatar = '/assets/images/header/avatar.png';
  isOpen = false;
  homeUrl = environment.homePath;
  appUrl = 'https://app.simpli.fi';
  profileUrl = '/main/profile';
  user: User = this.store.getUser();
  pubnubListnerObj!: Pubnub.ListenerParameters;
  unreadNotif = false;
  constructor(
    private readonly store: UserSessionStoreService,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly pubnubService: PubnubService,
    private readonly userProfileDataService: UserProfileDataService,
    private readonly breakpointObserver: BreakpointObserver,
    @Inject(TOASTER_SERVICE_KEY) private readonly toaster: IToaster,
    private readonly planFacadeService: PlanFacadeService,
    private readonly downloadHelperService: DownloadHelperService,
    private readonly snackBar: MatSnackBar,
    private elRef: ElementRef,
  ) {
    super();
    this.userProfileDataService.userInfoObv.subscribe(userInfo => {
      const userDetails = userInfo;
      if (this.user && userDetails) {
        this.user = userInfo;
        this.store.setUser(this.user);
      }
    });
  }

  /**
   * The ngOnInit function calls the pubnubListner method when the component is initialized.
   */
  ngOnInit() {
    this.pubnubListner();

    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe(result => {
      this.headerLogo = result.matches
        ? this.extraSmallSimplifiLogo
        : this.SimplifiLogo;
    });
  }

  /**
   * The `logout` function logs out the user by unsubscribing from PubNub channels, clearing the store,
   * and navigating to the login page.
   */
  logout() {
    this.authService.logout().subscribe(() => {
      this.pubnubService.unsubscribeAllChannels();
      this.store.clearAll();
      this.router.navigate([environment.loginPath]);
    });
  }

  /**
   * The function `pubnubListner` sets up a listener to handle incoming messages and triggers a logout
   * action if a specific type of system notification is received.
   */
  pubnubListner() {
    this.pubnubListnerObj = {
      message: m => {
        const message = JSON.parse(m.message.description);
        if (
          message.notificationType === NotificationType.SystemNotif &&
          message.action === 'logout'
        ) {
          this.logout();
        } else if (message.notificationType === NotificationType.PlanNotif) {
          this.processPlanNotification(message);
        } else {
          // sonar
        }
      },
    };
    this.pubnubService.pubnub?.addListener(this.pubnubListnerObj);
  }

  /**
   * Navigates the user to their profile page.
   */
  navigateToProfile() {
    this.router.navigate([this.profileUrl]);
  }

  /**
   * Closes the overlay by setting the `isOpen` flag to `false`.
   */
  closeOverlay() {
    this.isOpen = false;
  }

  /**
   * Retrieves the tooltip content based on the provided name.
   *
   * @param name - The name to generate the tooltip content from.
   * @returns The name if it is longer than 10 characters; otherwise, an empty string.
   */
  getTooltipContent(name: string): string {
    return name && name.length > 10 ? name : '';
  }

  /**
   * Processes a plan notification message by updating the unread notification status
   * and setting up the new notification details.
   *
   * @param message - The notification message to process.
   */
  processPlanNotification(message: INotification) {
    this.unreadNotif = true;
    if (message?.metadata?.action === 'download-plan') {
      this.downloadTargetFile(message?.metadata);
    }
  }

  /**
   * Toggles the visibility of the notification menu and marks notifications as read.
   */
  openNotifications() {
    this.showNotificationMenu = !this.showNotificationMenu;
    if (this.showNotificationMenu) {
      this.unreadNotif = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const notButton =
      this.elRef.nativeElement.querySelector('.notification-btn');
    const notMenu = this.elRef.nativeElement.querySelector(
      '.notifications-wrapper',
    );

    if (notButton && notMenu) {
      const clickedInsideButton = notButton.contains(event.target);
      const clickedInsideMenu = notMenu.contains(event.target);

      if (
        !clickedInsideButton &&
        !clickedInsideMenu &&
        this.showNotificationMenu
      ) {
        this.showNotificationMenu = false;
      }
    }
  }

  handleNotificationActions(event: INotificationAction) {
    this.downloadTargetFile(event);
    this.showNotificationMenu = false;
  }

  downloadTargetFile(event: INotificationAction) {
    // Open the snack bar dynamically and keep a reference to it
    const snackBarRef: MatSnackBarRef<SnackBarComponent> =
      this.snackBar.openFromComponent(SnackBarComponent, {
        data: {message: 'Download in progress...'},
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'snackbar-download',
      });
    this.planFacadeService
      .downloadPlanTarget(event.planId, event.versionId, event.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (response: IAnyObject) => {
          this.downloadHelperService.processPlanDownloads(response);
        },
        error: () => {
          // Update snackbar message on error
          snackBarRef.instance.data.message = 'Download failed.';
          snackBarRef.containerInstance.snackBarConfig.panelClass = [
            'snackbar-download-failed',
          ];
        },
        complete: () => {
          // Update snackbar message on completion
          snackBarRef.instance.data.message =
            'Download completed successfully.';
          snackBarRef._dismissAfter(Numbers.twothousand);
        },
      });
  }
}
