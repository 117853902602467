import {
  Directive,
  ElementRef,
  Renderer2,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[simplifiChatSizeAdjust]',
})
export class ChatSizeAdjustDirective implements AfterViewInit, OnDestroy {
  private scrollableElement!: HTMLElement;
  private resizeObserver!: ResizeObserver;
  private initialParentHeight!: number;
  private initialScrollableHeight!: number;
  private routerEventsSubscription!: Subscription;
  private mutationObserver!: MutationObserver;
  private previousHeight: string | null = null;

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2,
    private readonly router: Router,
  ) {}

  ngAfterViewInit() {
    this.scrollableElement = this.el.nativeElement.querySelector(
      '.scrollable',
    ) as HTMLElement;

    setTimeout(() => {
      this.calculateInitialHeights();
      this.setInitialHeight();
    }, 0);

    this.resizeObserver = new ResizeObserver(() => {
      const hasAiThinking =
        this.el.nativeElement.querySelector('.ai-thinking') !== null;
      if (!hasAiThinking) {
        this.adjustHeight();
      }
    });

    this.resizeObserver.observe(this.el.nativeElement);

    this.mutationObserver = new MutationObserver(mutations => {
      const hasAiThinking =
        this.el.nativeElement.querySelector('.ai-thinking') !== null;

      if (hasAiThinking) {
        this.handleAiThinking();
      } else {
        this.restoreHeightAndRecalculate();
      }
    });

    this.mutationObserver.observe(this.el.nativeElement, {
      childList: true,
      subtree: true,
    });

    this.routerEventsSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          this.calculateInitialHeights();
          this.setInitialHeight();
        }, 0);
      }
    });
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  private calculateInitialHeights() {
    this.initialParentHeight = this.el.nativeElement.clientHeight;
    this.initialScrollableHeight = this.scrollableElement.clientHeight;
  }

  private setInitialHeight() {
    this.renderer.setStyle(
      this.scrollableElement,
      'height',
      `${this.initialScrollableHeight}px`,
    );
  }

  private adjustHeight() {
    if (this.scrollableElement && this.initialParentHeight !== undefined) {
      const currentParentHeight = this.el.nativeElement.clientHeight;
      const heightDifference = currentParentHeight - this.initialParentHeight;

      const adjustedHeight = this.initialScrollableHeight + heightDifference;

      this.renderer.setStyle(
        this.scrollableElement,
        'height',
        `${adjustedHeight}px`,
      );
    }
  }

  private handleAiThinking() {
    // Store current height before setting to auto
    this.previousHeight =
      this.el.nativeElement.style.height ||
      `${this.el.nativeElement.clientHeight}px`;
    this.renderer.setStyle(this.el.nativeElement, 'height', 'auto');
    this.renderer.setStyle(this.el.nativeElement, 'resize', 'none');
  }

  private restoreHeightAndRecalculate() {
    // Restore previous height and recalculate
    if (this.previousHeight) {
      this.renderer.setStyle(
        this.el.nativeElement,
        'height',
        this.previousHeight,
      );
      this.previousHeight = null;
      // Set resize back to vertical
      this.renderer.setStyle(this.el.nativeElement, 'resize', 'vertical');
      setTimeout(() => {
        this.calculateInitialHeights();
        this.adjustHeight();
      }, 0);
    }
  }
}
