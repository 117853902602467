import {Injectable} from '@angular/core';
import {AnyAdapter, ApiService} from '@simplifi/core/api';
import {
  DeletePlanBulkCommand,
  GetPlanListCommand,
  GetPlanListVersionsCommand,
  GetPlanResultIoBudgetMediaTypeCommand,
  GetPlanResultZipCodeCountCommand,
  GetPlanResultZipCodeListCommand,
} from '../../planner/commands';
import {PlanListAdapterService} from '../adapters/plan-list-adapter.service';
import {cloneDeep, isEmpty} from 'lodash';
import {HttpParams} from '@angular/common/http';
import {PlanListResponse} from '../../../shared/interfaces';
import {Observable, take} from 'rxjs';
import {UserSessionStoreService} from '@simplifi/core/store';
import {
  CreatePlan,
  CreatePlanVersionCommand,
  CreatePlanViaAiCommand,
  GetContentCategories,
  DowloadPlanTargetData,
  GetPlanVersionDetailsCommand,
  GetDeviceTypes,
  PlanCopyCommand,
  PlanTargetCsvDownloadRequest,
  UpdatePlanCommand,
  UpdatePlanVersion,
  UpdatePlanVersionCurrentPage,
  GetCampaignGoalsCommand,
  GetCampaignGoalsCountCommand,
  GetBudgetConfigStatusCommand,
} from 'src/app/main/planner/create-plan/commands';
import {
  CampaignGoal,
  ContentCategory,
  Plan,
  PlanCopyDto,
  PlanDto,
} from 'src/app/main/planner/create-plan/models';
import {GoalDropdownAdapterService, PlanAdapterService} from '../adapters';
import {PlanFilter} from '@simplifi/shared/models/plan-filter.model';
import {FilterOperator, PlanBudgetStrategy} from '@simplifi/shared/enums';
import {
  PlanResultView,
  PlanResultViewCount,
  PlanView,
} from '@simplifi/shared/models';
import {PlanVersion} from '../create-plan/models/plan-version.model';
import {ResultIoBudgetMediaTypeDto} from '../create-plan/models/result-io-budget-media-type-dto.model';
import {IAnyObject} from '@simplifi/core/i-any-object';
import {DeviceType} from '../create-plan/models/device-type.model';
import {DateTime} from 'luxon';

@Injectable()
export class PlanFacadeService {
  tenantId: string;
  constructor(
    private readonly apiService: ApiService,
    private readonly planListAdapter: PlanListAdapterService,
    private readonly goalDropdownAdapter: GoalDropdownAdapterService,
    private readonly planAdapter: PlanAdapterService,
    private readonly anyAdapter: AnyAdapter,
    private readonly store: UserSessionStoreService,
  ) {
    this.tenantId = this.store.getUser().defaultTenantId;
  }

  /**
   * This function retrieves a list of plans based on specified filters and search text, with default
   * limits and offsets.
   * @param [limit=10] - The `limit` parameter in the `getPlanList` function specifies the maximum number
   * of items to retrieve in a single request. In this case, the default value is set to 10, meaning that
   * by default, the function will retrieve up to 10 items unless specified otherwise.
   * @param [offset=0] - The `offset` parameter in the `getPlanList` function is used to specify the
   * starting index from which the list of plans should be retrieved. It determines how many plans should
   * be skipped from the beginning of the list before fetching the desired number of plans specified by
   * the `limit` parameter.
   * @param {PlanFilter} [planFilter] - The `planFilter` parameter is an optional parameter that allows
   * you to filter the list of plans based on certain criteria. It is of type `PlanFilter`, which likely
   * contains properties or criteria that can be used to filter the plans. In the `getPlanList` function,
   * the `planFilter
   * @param {string} [searchText] - The `searchText` parameter in the `getPlanList` function is a string
   * that allows you to search for specific text within the plans. When you call the `getPlanList`
   * function, you can provide a `searchText` value to filter the list of plans based on the text you
   * @returns The `getPlanList` function is returning the result of executing the `command` object, which
   * is an instance of the `GetPlanListCommand` class.
   */
  getPlanList(
    limit = 10,
    offset = 0,
    planFilter?: PlanFilter,
    searchText?: string,
  ) {
    const command: GetPlanListCommand<PlanListResponse> =
      new GetPlanListCommand(
        this.apiService,
        this.planListAdapter,
        this.tenantId,
      );
    const queryObject = {
      skip: offset,
      planFilter,
      searchText,
      limit,
    };
    if (planFilter) {
      const filter = cloneDeep(planFilter);
      if (filter?.versionCreatedOn?.operator === FilterOperator.EQ) {
        filter.versionCreatedOn.operator = FilterOperator.BETWEEN;
      }
      if (filter?.versionCreatedOn?.operator === FilterOperator.GT) {
        filter.versionCreatedOn.value = String(
          DateTime.fromISO(String(filter?.versionCreatedOn.value))
            .endOf('day')
            .toISO(),
        );
      }
      queryObject.planFilter = filter;
    }
    if (!isEmpty(queryObject)) {
      const queryStr = JSON.stringify(queryObject);
      command.parameters = {
        query: new HttpParams().set('filter', queryStr),
      };
    }
    return command.execute();
  }

  /**
   * This function retrieves versions of plan lists based on provided plan IDs.
   * @param {string[]} planIds - Plan IDs are unique identifiers for different plans in the system. In
   * the `getPlanListVersions` function, the `planIds` parameter is an array of strings that contains
   * the IDs of the plans for which you want to retrieve the list of versions.
   * @returns The `getPlanListVersions` function is returning the result of executing the
   * `GetPlanListVersionsCommand` command with the provided `planIds`.
   */
  getPlanListVersions(planIds: string[]) {
    const command: GetPlanListVersionsCommand<PlanListResponse> =
      new GetPlanListVersionsCommand(
        this.apiService,
        this.planListAdapter,
        this.tenantId,
      );
    command.parameters = {
      data: {planIds},
    };
    return command.execute();
  }

  /**
   * The function `getCampaignGoalData` retrieves campaign goal data using a command object with
   * specified parameters.
   * @returns An Observable of CampaignGoal array is being returned from the `getCampaignGoalData`
   * function.
   */
  getCampaignGoalData(
    pagination = false,
    getActiveGoalsOnly = true,
    limit = 10,
    skip = 0,
    searchText?: string,
    isDropdown = false,
  ): Observable<CampaignGoal[]> {
    let command = new GetCampaignGoalsCommand(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
    );
    if (isDropdown) {
      command = new GetCampaignGoalsCommand(
        this.apiService,
        this.goalDropdownAdapter,
        this.tenantId,
      );
    }

    let where: IAnyObject = {
      name: {ilike: `%${searchText ?? ''}%`},
    };
    if (getActiveGoalsOnly) {
      where = {
        ...where,
        isActive: true,
      };
    }
    let queryObject: IAnyObject = {
      order: ['modifiedOn DESC', 'createdOn DESC'],
      where,
    };
    if (pagination) {
      queryObject = {
        ...queryObject,
        limit,
        skip,
      };
    }

    if (!isEmpty(queryObject)) {
      const queryStr = JSON.stringify(queryObject);
      command.parameters = {
        query: new HttpParams().set('filter', queryStr),
      };
    }
    return command.execute();
  }

  /**
   * Retrieves the count of campaign goals based on the specified parameters.
   *
   * @param {number} [limit=10] - The maximum number of campaign goals to retrieve.
   * @param {number} [offset=0] - The number of campaign goals to skip before starting to collect the result set.
   * @param {string} [searchText] - Optional search text to filter campaign goals by name.
   * @returns {Promise<{count!: number;}>} - A promise that resolves to the count of campaign goals.
   */
  getCampaignGoalDataCount(limit = 10, offset = 0, searchText?: string) {
    const command: GetCampaignGoalsCountCommand<{
      count: number;
    }> = new GetCampaignGoalsCountCommand(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
    );
    let queryObject = {
      skip: offset,
      limit,
      where: {},
    };
    if (searchText) {
      const where = {
        or: [{name: {ilike: `%${searchText ?? ''}%`}}],
      };
      queryObject = {
        where,
        skip: offset,
        limit,
      };
    }
    if (!isEmpty(queryObject)) {
      const queryStr = JSON.stringify(queryObject);
      command.parameters = {
        query: new HttpParams().set('filter', queryStr),
      };
    }
    return command.execute();
  }

  getDeviceTypeData(): Observable<DeviceType[]> {
    const params = new HttpParams();
    const command: GetDeviceTypes<DeviceType> = new GetDeviceTypes(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
    );
    command.parameters = {
      query: params,
    };
    return command.execute();
  }

  /**
   * This function retrieves content categories data using a command object with specified parameters.
   * @returns An Observable of ContentCategory array is being returned.
   */
  getContentCategoriesData(): Observable<ContentCategory[]> {
    const params = new HttpParams();
    const command: GetContentCategories<ContentCategory> =
      new GetContentCategories(this.apiService, this.anyAdapter, this.tenantId);
    command.parameters = {
      query: params,
    };
    return command.execute();
  }

  /**
   * The function `createPlanInformation` creates a new plan using the provided data and returns an
   * observable of the created plan.
   * @param {PlanDto} data - The `createPlanInformation` function takes a `PlanDto` object as a
   * parameter. This object likely contains information about a specific plan that needs to be created
   * or updated. The function then creates a `CreatePlan` command with the necessary parameters and
   * executes it to perform the actual creation or update operation
   * @returns An Observable of type PlanDto is being returned.
   */
  createPlanInformation(data: PlanDto): Observable<PlanDto> {
    const command: CreatePlan<PlanDto> = new CreatePlan(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
    );
    command.parameters = {
      data,
    };
    return command.execute();
  }

  /**
   * The function `createPlanCopy` creates a copy of a plan using the provided data.
   * @param {PlanCopyDto} data - PlanCopyDto {
   * @returns An Observable of type PlanCopyDto is being returned.
   */
  createPlanCopy(data: PlanCopyDto): Observable<PlanCopyDto> {
    const command: PlanCopyCommand<PlanCopyDto> = new PlanCopyCommand(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      data.id,
      data.versionId,
    );
    command.parameters = {
      data,
    };
    return command.execute();
  }

  /**
   * The function `createPlanThroughAi` creates a plan using AI by executing a command with specific
   * parameters.
   * @returns The `createPlanThroughAi()` function is returning the result of executing the
   * `CreatePlanViaAiCommand` command with the specified parameters, which enables AI for creating a
   * plan.
   */
  createPlanThroughAi() {
    const command = new CreatePlanViaAiCommand(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
    );
    command.parameters = {
      data: {
        enableAi: true,
      },
    };
    return command.execute();
  }

  /**
   * The function `createNewPlanVersion` creates a new plan version using the provided data.
   * @param {PlanCopyDto} data - The `createNewPlanVersion` function takes in a parameter `data` of type
   * `PlanCopyDto`. This parameter is used to create a new plan version by passing it to a
   * `CreatePlanVersionCommand` instance along with other required parameters like `apiService`,
   * `anyAdapter`, `tenant
   * @returns An Observable of type PlanCopyDto is being returned.
   */
  createNewPlanVersion(data: PlanCopyDto): Observable<PlanCopyDto> {
    const command: CreatePlanVersionCommand<PlanCopyDto> =
      new CreatePlanVersionCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        data.id,
        data.versionId,
      );
    command.parameters = {
      data,
    };
    return command.execute();
  }

  /**
   * The function `savePlanVersionInformation` saves updated plan information for a specific plan
   * version.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of the plan for which you want to save version information.
   * @param {string} currentVersionId - The `currentVersionId` parameter in the
   * `savePlanVersionInformation` function represents the ID of the current version of the plan that you
   * want to update.
   * @param {Plan} data - The `data` parameter in the `savePlanVersionInformation` function represents
   * the plan data that you want to save or update. It is of type `Plan`, which likely contains
   * information about a specific plan, such as its name, description, start date, end date, etc. When
   * calling the
   * @returns An Observable of type Plan is being returned.
   */
  savePlanVersionInformation(
    planId: string,
    currentVersionId: string,
    data: Plan,
  ): Observable<Plan> {
    const command: UpdatePlanVersion<Plan> = new UpdatePlanVersion(
      this.apiService,
      this.planAdapter,
      this.tenantId,
      planId,
      currentVersionId,
    );
    command.parameters = {
      data,
    };
    return command.execute();
  }

  /**
   * The function `updatePlan` updates a plan with the provided `planId` and `data` using an
   * `UpdatePlanCommand`.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of the plan that you want to update.
   * @param data - The `data` parameter in the `updatePlan` function is of type `Partial<Plan>`, which
   * means it is an object that may contain some or all of the properties of the `Plan` interface. This
   * allows you to update only specific properties of a plan without having to provide values for
   * @returns The `updatePlan` function is returning the result of executing the `command` object using
   * the `execute` method.
   */
  updatePlan(planId: string, data: Partial<Plan>) {
    const command: UpdatePlanCommand<Partial<Plan>> = new UpdatePlanCommand(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      planId,
    );
    command.parameters = {
      data,
    };
    return command.execute();
  }

  /**
   * The function `getPlanVersionData` retrieves plan version details using a command object in
   * TypeScript.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of a specific plan. It is used to identify the plan for which you want to retrieve version data.
   * @param {string} currentVersionId - The `currentVersionId` parameter is a string that represents
   * the ID of the current version of the plan for which you want to retrieve data. This ID is used to
   * identify the specific version of the plan that you are interested in.
   * @returns An Observable of type PlanView is being returned.
   */
  getPlanVersionData(
    planId: string,
    currentVersionId: string,
  ): Observable<PlanView> {
    const params = new HttpParams();
    const command: GetPlanVersionDetailsCommand<PlanView> =
      new GetPlanVersionDetailsCommand(
        this.apiService,
        this.planAdapter,
        this.tenantId,
        planId,
        currentVersionId,
      );
    command.parameters = {
      query: params,
    };
    return command.execute();
  }

  /**
   * The function `deletePlansBulk` deletes multiple plans in bulk based on the provided planIds and
   * deleteAll flag.
   * @param {string[]} [planIds] - The `planIds` parameter is an optional array of strings that contains
   * the IDs of the plans that you want to delete in bulk. If `planIds` is not provided, it will default
   * to `undefined`.
   * @param [deleteAll=false] - The `deleteAll` parameter is a boolean flag that determines whether all
   * plans should be deleted or only the plans specified by their IDs in the `planIds` array. If
   * `deleteAll` is set to `true`, all plans will be deleted. If `deleteAll` is set to `
   * @returns The `deletePlansBulk` method is returning the result of the `execute()` method called on
   * the `command` object.
   */
  deletePlansBulk(planIds?: string[], deleteAll = false) {
    const command = new DeletePlanBulkCommand(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
    );
    command.parameters = {
      data: {
        planIds,
        deleteAll,
      },
    };
    return command.execute();
  }

  /**
   * This function retrieves data for a table based on the provided plan and version IDs, with optional
   * search text and result activation criteria.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of a plan.
   * @param {string} versionId - The `versionId` parameter is a string that represents the version
   * identifier of a plan. It is used to specify which version of a plan to retrieve data for when
   * calling the `getPlanResultLocationsTableData` function.
   * @param [limit=10] - The `limit` parameter in the `getPlanResultLocationsTableData` function
   * specifies the maximum number of results to be returned in a single query. In this case, the
   * default value for `limit` is set to 10, meaning that by default, the function will return up to 10
   * @param [offset=0] - The `offset` parameter is used to specify the starting point from which data
   * should be retrieved in a paginated result set. It determines how many records to skip before
   * returning the data. In the `getPlanResultLocationsTableData` function provided, the `offset`
   * parameter is set to a default
   * @param [isResultActive=true] - The `isResultActive` parameter in the
   * `getPlanResultLocationsTableData` function is a boolean parameter that determines whether to
   * include only active results in the query. When `isResultActive` is set to `true`, only active
   * results will be included in the query. If it is set
   * @param {string} [searchText] - The `searchText` parameter in the `getPlanResultLocationsTableData`
   * function is used to filter the results based on a specific text input. If provided, the function
   * will search for zip codes that contain the specified text within them.
   * @returns The function `getPlanResultLocationsTableData` is returning the result of executing the
   * `command` object, which is an instance of `GetPlanResultZipCodeListCommand` class.
   */
  getPlanResultLocationsTableData(
    planId: string,
    versionId: string,
    strategy: string,
    limit = 10,
    offset = 0,
    isResultActive = true,
    searchText?: string,
  ) {
    const command: GetPlanResultZipCodeListCommand<PlanResultView> =
      new GetPlanResultZipCodeListCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        versionId,
        strategy,
      );
    let queryObject = {
      skip: offset,
      limit,
      where: {},
    };
    if (searchText) {
      const where = {
        or: [{zipCode: {ilike: `%${searchText}%`}}],
      };
      queryObject = {
        where,
        skip: offset,
        limit,
      };
    }
    if (isResultActive && !isEmpty(queryObject)) {
      const queryStr = JSON.stringify(queryObject);
      command.parameters = {
        query: new HttpParams().set('filter', queryStr),
      };
    }
    return command.execute();
  }

  /**
   * This function retrieves data count for plan result locations based on specified parameters such as
   * planId, versionId, limit, offset, and optional searchText.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of a plan. It is used to specify which plan's result locations table data count should be
   * retrieved.
   * @param {string} versionId - The `versionId` parameter is a string that represents the version
   * identifier of a plan. It is used to specify which version of a plan to retrieve data for in the
   * `getPlanResultLocationsTableDataCount` function.
   * @param [limit=10] - The `limit` parameter in the `getPlanResultLocationsTableDataCount` function
   * specifies the maximum number of results to be returned in a single query. In this case, the
   * default value for `limit` is set to 10, meaning that by default, the function will return up to
   * @param [offset=0] - The `offset` parameter is used to specify the starting point from which data
   * should be retrieved in a paginated result set. It determines how many records to skip before
   * returning the data. In the `getPlanResultLocationsTableDataCount` function, the `offset` parameter
   * is set to a default
   * @param {string} [searchText] - The `searchText` parameter in the
   * `getPlanResultLocationsTableDataCount` function is used to filter the results based on a specific
   * text input. If a `searchText` value is provided, the function will search for zip codes that
   * contain the specified text within them. The search is case
   * @returns The function `getPlanResultLocationsTableDataCount` is returning the result of executing
   * the `command` object, which is an instance of `GetPlanResultZipCodeCountCommand`.
   */
  getPlanResultLocationsTableDataCount(
    planId: string,
    versionId: string,
    strategy: string,
    limit = 10,
    offset = 0,
    searchText?: string,
  ) {
    const command: GetPlanResultZipCodeCountCommand<PlanResultViewCount> =
      new GetPlanResultZipCodeCountCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        versionId,
        strategy,
      );
    let queryObject = {
      skip: offset,
      limit,
      where: {},
    };
    if (searchText) {
      const where = {
        or: [{zipCode: {ilike: `%${searchText}%`}}],
      };
      queryObject = {
        where,
        skip: offset,
        limit,
      };
    }
    if (!isEmpty(queryObject)) {
      const queryStr = JSON.stringify(queryObject);
      command.parameters = {
        query: new HttpParams().set('filter', queryStr),
      };
    }
    return command.execute();
  }

  /**
   * The function `savePlanVersionCurrentPageData` updates the current page data for a specific plan
   * version.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of a specific plan.
   * @param {string} versionId - The `versionId` parameter is a string that represents the unique
   * identifier of a specific version of a plan. It is used to identify and reference a particular
   * version when performing operations or actions related to that specific version of the plan.
   * @param {PlanVersion} planVersion - The `planVersion` parameter in the
   * `savePlanVersionCurrentPageData` function represents the data of the current page that needs to be
   * saved for a specific plan version. This data could include information such as the content of the
   * page, user inputs, or any changes made to the plan version.
   */
  savePlanVersionCurrentPageData(
    planId: string,
    versionId: string,
    planVersion: PlanVersion,
  ) {
    this.updatePlanVersionCurrentPageRequest(planId, versionId, planVersion)
      .pipe(take(1))
      .subscribe();
  }

  /**
   * The function `updatePlanVersionCurrentPageRequest` updates the current page of a plan version.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of the plan for which the current page version is being updated.
   * @param {string} currentVersionId - The `currentVersionId` parameter in the
   * `updatePlanVersionCurrentPageRequest` function represents the ID of the current version of the
   * plan that you want to update. This ID is used to identify the specific version of the plan that
   * you are working with in order to make the necessary updates.
   * @param {PlanVersion} data - The `data` parameter in the `updatePlanVersionCurrentPageRequest`
   * function represents the updated information for a specific plan version. This could include
   * changes to the plan's content, settings, or any other relevant details that need to be updated.
   * @returns An Observable of type PlanVersion is being returned.
   */
  updatePlanVersionCurrentPageRequest(
    planId: string,
    currentVersionId: string,
    data: PlanVersion,
  ): Observable<PlanVersion> {
    const command: UpdatePlanVersionCurrentPage<PlanVersion> =
      new UpdatePlanVersionCurrentPage(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        currentVersionId,
      );
    command.parameters = {
      data,
    };
    return command.execute();
  }

  /**
   * This function retrieves the budget media type results for a specific plan and version.
   * @param {string} planId - The `planId` parameter is a string that represents the unique identifier
   * of a specific plan. It is used to identify which plan's result budget media type needs to be
   * retrieved.
   * @param {string} versionId - The `versionId` parameter is a string that represents the unique
   * identifier of a specific version within a plan. It is used to identify and retrieve data related to
   * a particular version of a plan.
   * @returns An Observable of ResultIoBudgetMediaTypeDto[] is being returned.
   */
  getPlanResultBudgetMediaType(
    planId: string,
    versionId: string,
    strategy: PlanBudgetStrategy,
  ): Observable<ResultIoBudgetMediaTypeDto[]> {
    const command: GetPlanResultIoBudgetMediaTypeCommand<ResultIoBudgetMediaTypeDto> =
      new GetPlanResultIoBudgetMediaTypeCommand(
        this.apiService,
        this.anyAdapter,
        this.tenantId,
        planId,
        versionId,
        strategy,
      );
    return command.execute();
  }

  /**
   * Initiates a request to download a CSV file for a specific plan and version.
   *
   * This method constructs a `PlanTargetCsvDownloadRequest` command using the provided parameters
   * and executes the command to retrieve the desired CSV file.
   *
   * @param {string} planId - The unique identifier of the plan for which the CSV file is being requested.
   * @param {string} versionId - The version of the plan for which the CSV file is being requested.
   * @param {string} fileType - The type of file to be downloaded (e.g., 'csv').
   * @param {string} contentType - The content type of the file (e.g., 'text/csv').
   *
   * @returns {Promise<any>} A promise that resolves when the command executes the file download.
   */
  planTargetCsvDownloadRequest(
    planId: string,
    versionId: string,
    fileType: string,
    contentType: string,
  ) {
    const command = new PlanTargetCsvDownloadRequest(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      planId,
      versionId,
      fileType,
      this.store.getBudgetStrategy(),
    );

    command.parameters = {
      data: {contentType},
    };
    return command.execute();
  }

  downloadPlanTarget(planId: string, versionId: string, fileId: string) {
    const command = new DowloadPlanTargetData(
      this.apiService,
      this.anyAdapter,
      this.tenantId,
      planId,
      versionId,
      fileId,
    );
    command.parameters = {
      responseType: 'blob',
      observe: 'response',
    };
    return command.execute();
  }

  getBudgetConfigStatus(planId: string, versionId: string) {
    const command = new GetBudgetConfigStatusCommand(
      this.apiService,
      this.anyAdapter,
      planId,
      versionId,
      this.tenantId,
    );
    return command.execute();
  }
}
