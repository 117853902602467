export enum PlanTargetIcon {
  Addressable = 'el-icon-address',
  Contextual = 'el-icon-contextual',
  Search = 'el-icon-search-eye',
  Ztv = '/assets/images/campaign-plan/ztv.svg',
}

export enum PlanTarget {
  Addressable = 'addressable',
  Contextual = 'contextual',
  Ztv = 'ztv',
  keyword = 'keyword',
}

export enum PlanTargetLabels {
  addressable = 'Addressable',
  contextual = 'Third Party Segments',
  ztv = 'Zip Adviser',
  keyword = 'Keywords',
}

export const TargetIconMap = {
  [PlanTarget.Addressable]: PlanTargetIcon.Addressable,
  [PlanTarget.Contextual]: PlanTargetIcon.Contextual,
  [PlanTarget.keyword]: PlanTargetIcon.Search,
  [PlanTarget.Ztv]: PlanTargetIcon.Ztv,
};

export const TargetTypeMapping: Record<string, string> = {
  Keywords: 'Keyword',
  ZipSelect: 'ZipAdviser',
  Contextual: 'ThirdPartySegment',
};

export enum MediaTypeName {
  OTT = 'OTT/CTV',
  Display = 'Display',
  Video = 'Video',
}

export enum MediaTypeKeys {
  OTT = 'ottCtv',
  Display = 'display',
  Video = 'video',
}

export const targetKeys = [
  MediaTypeKeys.Display,
  MediaTypeKeys.Video,
  MediaTypeKeys.OTT,
];

const recomendedPercentage = [
  {target: PlanTarget.Addressable, awareness: 25, performance: 25},
  {target: PlanTarget.Contextual, awareness: 20, performance: 10},
  {target: PlanTarget.Ztv, awareness: 35, performance: 25},
  {target: PlanTarget.keyword, awareness: 20, performance: 40},
];

export const awarnessGoalMap = new Map(
  recomendedPercentage.map(obj => [obj.target, obj.awareness]),
);
export const performanceGoalMap = new Map(
  recomendedPercentage.map(obj => [obj.target, obj.performance]),
);
