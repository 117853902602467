import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, EventEmitter, Input, Output, inject} from '@angular/core';
import {MatChipEditedEvent, MatChipInputEvent} from '@angular/material/chips';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {NgForm} from '@angular/forms';
import {Messages} from '@simplifi/shared/constants';

export interface Item {
  name: string;
}

@Component({
  selector: 'simplifi-input-chips',
  templateUrl: './input-chips.component.html',
  styleUrl: './input-chips.component.scss',
})
export class InputChipsComponent {
  addOnBlur = true;
  @Input() form!: NgForm;
  @Input() id!: string;
  @Input() name!: string;
  @Input() label?: string;
  @Input() hintText?: string;
  @Input() maxlength!: number;
  @Input() required = false;
  @Input() inputModel!: string;
  @Input() pattern!: string;
  @Output() isInputValid = new EventEmitter();
  @Output() inputModelChange = new EventEmitter();
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  items: Item[] = [];
  errorMessage = '';

  onChange(event: Event) {
    const pattern = new RegExp(this.pattern);
    const value = String(event);

    if (value) {
      const emails = value.split(/[,\s]+/).filter(email => email.length > 0);
      const allValid = emails.every(email => pattern.test(email.trim()));
      this.isInputValid.emit(allValid);
      this.inputModelChange.emit(this.items);
      this.errorMessage = allValid ? '' : Messages.invalidEmailFormat;
    }
  }

  announcer = inject(LiveAnnouncer);

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    const pattern = new RegExp(this.pattern);

    if (value) {
      const emails = value.split(/[,\s]+/).filter(email => email.length > 0);
      let allValid = true;

      emails.forEach(email => {
        if (pattern.test(email.trim())) {
          this.items.push({name: email.trim()});
        } else {
          allValid = false;
        }
      });
      this.isInputValid.emit(allValid);
      this.inputModelChange.emit(this.items);
      this.errorMessage = '';
      // Clear the input value
      event.chipInput!.clear();
    }
  }

  remove(item: Item): void {
    const index = this.items.indexOf(item);
    if (index >= 0) {
      this.items.splice(index, 1);
      this.inputModelChange.emit(this.items);
      this.announcer.announce(`Removed ${JSON.stringify(item)}`);
    }
  }

  edit(item: Item, event: MatChipEditedEvent) {
    const value = event.value.trim();
    const pattern = new RegExp(this.pattern);

    if (!value) {
      this.remove(item);
    } else if (pattern.test(value)) {
      const index = this.items.indexOf(item);
      if (index >= 0) {
        this.items[index].name = value;
        this.isInputValid.emit(true);
        this.errorMessage = '';
      }
    } else {
      this.isInputValid.emit(false);
      this.errorMessage = Messages.invalidEmailFormat;
    }
    this.inputModelChange.emit(this.items);
  }
}
