import {environment} from '@simplifi/env/environment';
import * as CryptoJS from 'crypto-js';

export class EncryptDecrypt {
  encrypt<T>(data: T): string {
    return (
      data &&
      CryptoJS.AES.encrypt(
        JSON.stringify(data),
        environment.cryptoSecretKey,
      ).toString()
    );
  }

  decrypt<T>(data: string): T {
    try {
      const decryptData =
        data && CryptoJS.AES.decrypt(data, environment.cryptoSecretKey);
      return decryptData && JSON.parse(decryptData.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      localStorage.clear();
      window.location.reload();
      return data as T;
    }
  }
}
