import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IChatData} from '@simplifi/shared/interfaces';
import {ChatFacadeService} from '@simplifi/shared/modules/chat-wrapper/services';
import {take} from 'rxjs';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Words} from '@simplifi/main/planner/create-plan/interfaces';

@Component({
  selector: 'simplifi-chat-data-dialog',
  templateUrl: './chat-data-dialog.component.html',
  styleUrls: ['./chat-data-dialog.component.scss'],
})
export class ChatDataDialogComponent {
  chatData: IChatData[] = [];
  loaderId = 'loader-chat-data';
  isLoading = false;
  noChatDataIcon = '/assets/images/planner/chat-icon.svg';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {channelId: string; messageId: string},
    public dialogRef: MatDialogRef<ChatDataDialogComponent>,
    private readonly chatFacade: ChatFacadeService,
    private readonly ngxLoader: NgxUiLoaderService,
  ) {
    this.loadChatData();
  }

  private loadChatData(): void {
    this.isLoading = true;
    this.ngxLoader.startLoader(this.loaderId);
    this.chatFacade
      .getChatMessageById(this.data.channelId, this.data.messageId)
      .pipe(take(1))
      .subscribe({
        next: res => {
          this.ngxLoader.stopLoader(this.loaderId);
          const chatData = [
            {
              name: 'Location',
              values: res?.metadata?.locationValues || [],
            },
            {
              name: 'Addressable',
              values:
                res?.metadata?.targetValues?.addressable?.map(
                  (add: {name: string}) => add.name,
                ) || [],
            },
            {
              name: 'Third Party Segments',
              values:
                res?.metadata?.targetValues?.contextual?.map(
                  (add: {segmentName: string}) => add.segmentName,
                ) || [],
            },
            {
              name: 'Keyword',
              values:
                res?.metadata?.targetValues?.keyword
                  ?.filter((key: Words) => +(key.child_count ?? 0) === 0)
                  ?.map((add: {name: string}) => add.name) || [],
            },
          ];
          // Check if all values arrays are empty
          const allEmpty = chatData.every(item => item.values.length === 0);

          // Set chatData to empty if all are blank, otherwise assign the constructed array
          this.chatData = allEmpty ? [] : chatData;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.ngxLoader.stopLoader(this.loaderId);
        },
      });
  }
}
