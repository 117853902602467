import {Injectable} from '@angular/core';
import {IAdapter} from '@simplifi/core/api';
import {Budget, BudgetMediaType, MediaType, TargetMediaType} from '../models';
import {targetKeys} from '@simplifi/shared/enums';

@Injectable()
export class BudgetMediaTypeAdapter implements IAdapter<BudgetMediaType> {
  adaptToModel(resp: any): BudgetMediaType {
    return resp;
  }

  adaptFromModel(data: Budget): any {
    const payload: BudgetMediaType[] = [];
    data.targetMediaTypeMappings.forEach(mapping => {
      targetKeys.forEach(key => {
        const mediaType = mapping[key as keyof TargetMediaType] as MediaType;
        payload.push({
          planVersionId: data.planVersionId,
          targetMediaTypeId: mediaType.id,
          budgetAllocationActual: Number(mediaType.allocatedBudget),
          budgetAllocationDefault: Number(mediaType.defaultBudget),
          cpmActual: Number(mediaType.maxBid),
          cpmDefault: Number(mediaType.defaultMaxBid),
          recommendedPercentage: mapping.recommendedBudgetPercentange,
        });
      });
    });
    return payload;
  }
}
