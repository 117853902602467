import {Injectable} from '@angular/core';
import {SendMessageCommand} from '../commands/send-message.command';
import {ApiService} from '@simplifi/core/api';
import {
  GetChannelInfoCommand,
  GetChatById,
  GetChatMessageById,
  SendChannelSignalCommand,
} from '../commands';
import {AnyAdapter, UserSessionStoreService} from '@simplifi/core/index';
import {Chats} from '../models';
import {HttpParams} from '@angular/common/http';
import {ChatAdapterService, ChatMessageAdapter} from '../adapters';

@Injectable()
export class ChatFacadeService {
  constructor(
    private readonly apiService: ApiService,
    private readonly anyAdapter: AnyAdapter,
    private readonly sessionStore: UserSessionStoreService,
    private readonly chatAdapter: ChatAdapterService,
    private readonly chatMessageAdapter: ChatMessageAdapter,
  ) {}

  /**
   * Sends a chat message to the specified chat channel.
   *
   * Creates a `SendMessageCommand` with the provided chat data and executes it.
   * The command includes user information such as `defaultTenantId` and the message ID.
   *
   * @param data - The chat data of type `Chats` that contains the message details.
   * @returns A promise or observable that resolves when the message is successfully sent.
   */
  sendMessage(data: Chats) {
    const command: SendMessageCommand<Chats> = new SendMessageCommand(
      this.apiService,
      this.chatAdapter,
      this.sessionStore.getUser()?.defaultTenantId ?? '',
      data.id,
    );
    command.parameters = {
      data,
    };
    return command.execute();
  }

  /**
   * Retrieves information about a specific chat channel.
   *
   * Creates and executes a `GetChannelInfoCommand` using the provided channel ID.
   * The command includes user information such as `defaultTenantId`.
   *
   * @param channelId - The ID of the chat channel to retrieve information for.
   * @returns A promise or observable with the channel information when the command is executed.
   */
  getChannelInfo(channelId: string) {
    const command: GetChannelInfoCommand<Chats> = new GetChannelInfoCommand(
      this.apiService,
      this.chatAdapter,
      this.sessionStore.getUser()?.defaultTenantId ?? '',
      channelId,
    );

    return command.execute();
  }

  /**
   * Retrieves chat messages from the specified chat channel.
   *
   * Creates a `GetChatById` command to fetch chat messages, with optional pagination parameters.
   * The command includes a query with sorting, skip, and limit options. It executes the query and returns the results.
   *
   * @param channelId - The ID of the chat channel to fetch messages from.
   * @param skip - The number of messages to skip (for pagination). Default is 0.
   * @param limit - The maximum number of messages to retrieve. Default is 25.
   * @returns A promise or observable containing the chat messages for the specified channel.
   */
  getChatMessages(channelId: string, skip = 0, limit = 10) {
    const command = new GetChatById(
      this.apiService,
      this.chatMessageAdapter,
      this.sessionStore.getUser().defaultTenantId ?? '',
      channelId,
    );
    const query = {
      order: ['createdOn DESC'],
      skip,
      limit,
    };
    let params = new HttpParams();
    params = params.set('filter', JSON.stringify(query));
    command.parameters = {
      query: params,
    };
    return command.execute();
  }

  /**
   * This function retrieves a chat message by its ID within a specific channel.
   * @param {string} channelId - The `channelId` parameter is a string that represents the unique
   * identifier of the chat channel where the message is located. It is used to specify the specific
   * channel from which you want to retrieve a chat message.
   * @param {string} messageId - The `messageId` parameter is a unique identifier for a specific chat
   * message within a channel. It is used to retrieve the details of that particular message, such as
   * the content, sender, timestamp, etc.
   * @returns The `getChatMessageById` function is returning the result of executing the `command`
   * object, which is an instance of the `GetChatMessageById` class. The `execute()` method of the
   * `command` object is being called to retrieve the chat message identified by the `channelId` and
   * `messageId` parameters.
   */
  getChatMessageById(channelId: string, messageId: string) {
    const command = new GetChatMessageById(
      this.apiService,
      this.anyAdapter,
      this.sessionStore.getUser().defaultTenantId ?? '',
      channelId,
      messageId,
    );

    return command.execute();
  }

  /**
   * The function `sendChannelSignal` creates a new `SendChannelSignalCommand` object and executes it.
   * @param {string} channelId - The `channelId` parameter is a string that represents the unique
   * identifier of the channel to which you want to send a signal. This identifier is used to specify
   * the destination channel for the signal transmission.
   * @returns The `sendChannelSignal` function is returning the result of the `execute` method called
   * on the `SendChannelSignalCommand` instance created within the function.
   */
  sendChannelSignal(channelId: string) {
    const command = new SendChannelSignalCommand(
      this.apiService,
      this.anyAdapter,
      this.sessionStore.getUser().defaultTenantId ?? '',
      channelId,
    );

    command.parameters = {
      data: {
        channelId,
      },
    };

    return command.execute();
  }
}
