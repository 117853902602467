import {Injectable} from '@angular/core';
import {PlanView} from '../../../shared/models';
import {PlanListResponse} from '../../../shared/interfaces';
import {PlanBudgetStrategy, PlanStatus} from '@simplifi/shared/enums';

@Injectable()
export class PlanListAdapterService {
  adaptToModel(resp: any): PlanListResponse {
    const respArray: PlanView[] = [];
    if (resp?.planList?.length) {
      resp?.planList?.forEach((plan: any) => {
        const responseData = new PlanView();
        responseData.id = plan?.id;
        responseData.name = plan?.name;
        responseData.hasContextual = plan?.hasContextual;
        responseData.hasAddressable = plan?.hasAddressable;
        responseData.hasKeywords = plan?.hasKeywords;
        responseData.hasZtv = plan?.hasZtv;
        responseData.status = plan?.status;
        responseData.versionId = plan?.versionId;
        responseData.currentVersion = plan?.currentVersion;
        responseData.isCurrentVersion = plan?.isCurrentVersion;
        responseData.disabled =
          !plan?.isCurrentVersion || plan.status === PlanStatus.Downloaded;
        responseData.createdOn = plan?.versionCreatedOn;
        responseData.budget[PlanBudgetStrategy.Max].total = plan?.totalBudget;
        responseData.goalAmount = Number(plan?.campaignGoalAmount);
        responseData.version = plan?.version;
        responseData.planId = plan?.planId;
        responseData.goalName = plan?.campaignGoalName;
        responseData.versionTag = plan?.versionTag;
        responseData.budget.aiSuggested = !!plan?.isAiSuggestedBudget;
        responseData.currentPage = plan?.currentPage;
        respArray.push(responseData);
      });
    }
    return {planList: respArray, planListCount: resp?.planListCount};
  }

  adaptFromModel(data: PlanListResponse): any {
    return data;
  }
}
