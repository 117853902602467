import {PlanTarget, PlanTargetLabels} from '@simplifi/shared/enums';
import {MediaType} from './media-type.model';

export class TargetMediaType {
  targetType!: PlanTarget;
  targetLabel!: PlanTargetLabels;
  targetIcon!: string;
  recommendedBudgetPercentange!: number;
  targetBudget = 0;
  targetChartWidth = '';
  display!: MediaType;
  video!: MediaType;
  ottCtv!: MediaType;
  id!: string;

  constructor() {
    this.display = new MediaType();
    this.video = new MediaType();
    this.ottCtv = new MediaType();
  }
}
